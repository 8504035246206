.bard {
  & > *:not(:last-child) {
    @apply mb-4;
  }

  a {
    @apply rounded font-semibold outline-none transition text-red-500 hover:text-red-600 focus-visible:ring;
  }

  u {
    text-underline-offset: theme(textUnderlineOffset.2);
    text-decoration-thickness: 1px;
  }

  ol {
    @apply marker:text-red-500 marker:font-serif list-decimal pl-3 xs:pl-5 space-y-1;

    li {
      @apply pl-3;
    }
  }

  ul {
    @apply list-disc marker:text-red-500 pl-4 xs:pl-6 space-y-1;

    li {
      @apply pl-2;
    }
  }
}

.swiper {
  --swiper-pagination-color: theme('colors.gray.500');
  --swiper-pagination-bullet-inactive-color: theme('colors.gray.500');

  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-inactive-opacity: 0.4;
}

.swiper-wrapper {
  @apply items-center;
}

.swiper-vertical .swiper-pagination-vertical {
  right: auto;
  left: theme('spacing.6');
}

.swiper-pagination {
  pointer-events: none;

  .swiper-pagination-bullet {
    pointer-events: all;
  }
}

.swiper {
  @media (pointer: fine) {
    .swiper-button-prev,
    .swiper-button-next {
      @apply opacity-0;
    }

    &:hover {
      .swiper-button-prev,
      .swiper-button-next {
        @apply opacity-100;
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    @apply !h-8 !w-6 p-1;
  }

  @screen md {
    .swiper-button-prev,
    .swiper-button-next {
      @apply !h-10 !w-7 p-1;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    @apply transition-all duration-150;
    @apply bg-blue-900/40 !text-white;

    &::after {
      @apply !hidden;
    }

    &.swiper-button-disabled {
      @apply !opacity-0;
    }
  }

  .swiper-button-prev {
    @apply !left-0 rounded-r;
  }
  .swiper-button-next {
    @apply !right-0 rounded-l;
  }
}

.typo-1200 {
  @apply font-serif font-normal lowercase italic leading-[1.19] text-red-500;
  font-size: theme('fontSize.6xl');

  @screen md {
    font-size: theme('fontSize.8xl');
  }
}

.typo-1100 {
  @apply font-sans font-semibold leading-[1.21];
  font-size: theme('fontSize.5xl');
}

.typo-1100-regular {
  @apply font-sans font-normal leading-[1.21];
  font-size: theme('fontSize.5xl');
}

.typo-1000 {
  @apply font-sans font-semibold leading-[1.25];
  font-size: theme('fontSize.3xl');
}

.typo-1000-regular {
  @apply font-sans font-normal leading-[1.25];
  font-size: theme('fontSize.3xl');
}

.typo-900 {
  @apply font-sans font-semibold leading-[1.3];
  font-size: theme('fontSize.xl');
}

.typo-900-regular {
  @apply font-sans font-normal leading-[1.3];
  font-size: theme('fontSize.xl');
}

.typo-800 {
  @apply font-sans font-semibold leading-[1.33];
  font-size: theme('fontSize.lg');
}

.typo-800-regular {
  @apply font-sans font-normal leading-[1.33];
  font-size: theme('fontSize.lg');
}

.typo-700 {
  @apply font-serif font-normal lowercase italic leading-[1.15] tracking-[0.02em] text-red-500;
  font-size: theme('fontSize.4xl');
}

.typo-600 {
  @apply font-serif font-normal lowercase italic leading-[1.18] tracking-[0.02em] text-red-500;
  font-size: theme('fontSize.2xl');
}

.typo-500 {
  @apply font-serif font-normal lowercase italic leading-[1.22] tracking-[0.02em] text-red-500;
  font-size: theme('fontSize.lg');
}

.typo-400 {
  @apply font-sans font-semibold leading-[1.4];
  font-size: theme('fontSize.xl');
}

.typo-400-regular {
  @apply font-sans font-normal leading-[1.4];
  font-size: theme('fontSize.xl');
}

.typo-300 {
  @apply font-sans font-semibold leading-[1.33];
  font-size: theme('fontSize.lg');
}

.typo-300-regular {
  @apply font-sans font-normal leading-[1.33];
  font-size: theme('fontSize.lg');
}

.typo-200 {
  @apply font-sans font-semibold leading-[1.375];
  font-size: theme('fontSize.base');
}

.typo-200-regular {
  @apply font-sans font-normal leading-[1.375];
  font-size: theme('fontSize.base');
}

.typo-100 {
  @apply font-sans font-semibold leading-[1.29];
  font-size: theme('fontSize.sm');
}

.typo-100-regular {
  @apply font-sans font-normal leading-[1.29] text-gray-500;
  font-size: theme('fontSize.sm');
}

@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(calc(100% + 1.5rem));
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(var(--radix-toast-swipe-end-x));
  }
  to {
    transform: translateX(calc(100% + 1.5rem));
  }
}

@media (prefers-reduced-motion: no-preference) {
  .toast[data-state='open'] {
    animation: slideIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }
  .toast[data-state='closed'] {
    animation: hide 100ms ease-in forwards;
  }
  .toast[data-swipe='move'] {
    transform: translateX(var(--radix-toast-swipe-move-x));
  }
  .toast[data-swipe='cancel'] {
    @apply translate-x-0 transition-all;
  }
  .toast[data-swipe='end'] {
    animation: slideOut 100ms ease-out forwards;
  }
}
